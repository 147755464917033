/* Main container for chat assistant */
.chat-assistant {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 380px;
  max-width: calc(100vw - 40px);
  height: 500px;
  max-height: calc(100vh - 100px);
  background: white;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1),
              0 6px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 9990;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom right;
  animation: assistant-pop-in 0.3s forwards;
  font-family: 'Inter', 'Segoe UI', -apple-system, BlinkMacSystemFont, sans-serif;
  
  /* Ensure the chat assistant persists during page transitions */
  will-change: transform, opacity, width, height;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

@keyframes assistant-pop-in {
  from { opacity: 0; transform: scale(0.8); }
  to { opacity: 1; transform: scale(1); }
}

/* Minimized state */
.chat-assistant.minimized {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: linear-gradient(135deg, #4f46e5, #6366f1);
  box-shadow: 0 4px 15px rgba(79, 70, 229, 0.3);
  cursor: pointer;
  overflow: hidden;
}

.chat-assistant.minimized:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(79, 70, 229, 0.35);
}

/* Header bar */
.chat-assistant-header {
  background: linear-gradient(135deg, #4f46e5, #6366f1);
  color: white;
  padding: 14px 16px;
  cursor: pointer;
  min-height: 60px;
}

.header-content {
  display: flex;
  align-items: center;
  position: relative;
}

.assistant-icon {
  width: 32px;
  height: 32px;
  color: white;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assistant-icon svg {
  width: 24px;
  height: 24px;
}

.chat-assistant.minimized .assistant-icon {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-assistant-header h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  flex-grow: 1;
}

.chat-assistant.minimized .chat-assistant-header h3,
.chat-assistant.minimized .minimize-btn {
  display: none;
}

/* Minimize/Maximize button */
.minimize-btn {
  background: none;
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.minimize-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.minimize-btn svg {
  width: 20px;
  height: 20px;
}

/* Connection status indicator */
.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}

.status-dot.connected {
  background-color: #10b981;
  box-shadow: 0 0 0 rgba(16, 185, 129, 0.4);
  animation: pulse-green 1.5s infinite;
  margin-left: 8px;
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0.7);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(16, 185, 129, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0);
  }
}

/* Main chat body */
.chat-assistant-body {
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Conversation container */
.conversation-container {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  background-color: #ffffff;
  background-image: 
    radial-gradient(#eef2ff 0.5px, transparent 0.5px),
    radial-gradient(#eef2ff 0.5px, #ffffff 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 transparent;
}

.conversation-container::-webkit-scrollbar {
  width: 6px;
}

.conversation-container::-webkit-scrollbar-track {
  background: transparent;
}

.conversation-container::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 20px;
}

/* Empty state */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #94a3b8;
  padding: 2rem;
  text-align: center;
}

.empty-state svg {
  width: 3rem;
  height: 3rem;
  color: #cbd5e1;
  opacity: 0.7;
  margin-bottom: 1rem;
  animation: float 3s ease-in-out infinite;
}

.empty-state p {
  margin: 0;
  font-size: 0.95rem;
  max-width: 260px;
  line-height: 1.5;
  text-align: center;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

/* Message styling */
.message {
  margin-bottom: 1rem;
  max-width: 100%;
}

/* System message */
.system-message {
  background-color: #f1f5f9;
  border-left: 3px solid #94a3b8;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  color: #64748b;
  font-size: 0.9rem;
  font-style: italic;
  margin: 0 auto 12px;
  max-width: 90%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

/* User question */
.user-question {
  background: linear-gradient(135deg, #4f46e5, #6366f1);
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 18px 18px 4px 18px;
  margin-left: auto;
  max-width: 80%;
  position: relative;
  line-height: 1.5;
  font-size: 0.9rem;
  box-shadow: 0 2px 5px rgba(99, 102, 241, 0.2);
  animation: popInRight 0.3s ease;
}

@keyframes popInRight {
  from { opacity: 0; transform: translateX(10px) scale(0.97); }
  to { opacity: 1; transform: translateX(0) scale(1); }
}

.user-question strong {
  opacity: 0.9;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0.5px;
  display: block;
  margin-bottom: 4px;
}

/* AI answer */
.ai-answer {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 18px 18px 18px 4px;
  padding: 0.75rem 1rem;
  margin-right: auto;
  max-width: 80%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
  position: relative;
  line-height: 1.5;
  font-size: 0.9rem;
  animation: popInLeft 0.3s ease;
}

@keyframes popInLeft {
  from { opacity: 0; transform: translateX(-10px) scale(0.97); }
  to { opacity: 1; transform: translateX(0) scale(1); }
}

.ai-answer .answer {
  color: #1e293b;
  white-space: pre-line;
}

/* Status message */
.status-message {
  color: #64748b;
  font-style: italic;
  text-align: center;
  padding: 0.6rem 0.8rem;
  margin: 0.75rem auto;
  background-color: rgba(248, 250, 252, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 24px;
  display: inline-block;
  max-width: 85%;
  animation: pulse 1.5s infinite;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  font-size: 0.85rem;
}

@keyframes pulse {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

.status-message .spinner-border {
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.5rem;
  vertical-align: middle;
  border-width: 0.1rem;
  border-color: #6366f1 transparent #6366f1 transparent;
}

/* Error message */
.error-message {
  color: #dc2626;
  background-color: #fef2f2;
  border-left: 3px solid #ef4444;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  margin: 0.75rem auto;
  max-width: 85%;
  font-size: 0.85rem;
  box-shadow: 0 1px 3px rgba(239, 68, 68, 0.1);
}

/* Footer */
.chat-assistant-footer {
  padding: 1rem;
  border-top: 1px solid #f1f5f9;
  background: white;
  display: flex;
  align-items: center;
  position: relative;
}

.chat-assistant-footer::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, 
    transparent, 
    rgba(99, 102, 241, 0.3), 
    transparent
  );
}

/* Input container */
.input-container {
  display: flex;
  width: 100%;
  position: relative;
}

.input-container .form-control {
  border-radius: 24px !important;
  padding: 0.7rem 1rem !important;
  padding-right: 3rem !important;
  border: 1px solid #e2e8f0 !important;
  background-color: #f8fafc !important;
  font-size: 0.9rem !important;
  transition: all 0.2s ease-in-out !important;
  box-shadow: none !important;
  width: 100%;
}

.input-container .form-control:focus {
  border-color: #6366f1 !important;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.15) !important;
  background-color: white !important;
}

.input-container .btn {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #4f46e5, #6366f1) !important;
  border: none !important;
  box-shadow: 0 2px 5px rgba(99, 102, 241, 0.3) !important;
  transition: all 0.2s ease !important;
}

.input-container .btn:hover:not(:disabled) {
  transform: translateY(-50%) scale(1.05);
  box-shadow: 0 4px 8px rgba(99, 102, 241, 0.35) !important;
}

.input-container .btn:disabled {
  opacity: 0.7;
  background: #94a3b8 !important;
}

.input-container .btn svg {
  width: 20px;
  height: 20px;
  fill: white;
}

/* Connect button */
.connect-btn {
  width: 100%;
  background: linear-gradient(135deg, #4f46e5, #6366f1) !important;
  border: none !important;
  padding: 0.8rem 1.5rem !important;
  border-radius: 24px !important;
  font-weight: 500 !important;
  font-size: 0.9rem !important;
  transition: all 0.2s ease-in-out !important;
  box-shadow: 0 2px 5px rgba(99, 102, 241, 0.2) !important;
}

.connect-btn:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.3) !important;
}

.connect-btn.btn-loading {
  position: relative;
  pointer-events: none;
}

.connect-btn.btn-loading .spinner-border {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

/* Initial load animation */
.initial-load {
  animation: fadeInAssistant 0.5s ease-out;
}

@keyframes fadeInAssistant {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}

/* Media queries for responsive design */
@media (max-width: 576px) {
  .chat-assistant {
    bottom: 10px;
    right: 10px;
    width: calc(100vw - 20px);
    height: 450px;
  }
  
  .chat-assistant-header {
    padding: 12px;
  }
  
  .user-question, .ai-answer {
    max-width: 90%;
    font-size: 0.85rem;
  }
  
  .chat-assistant-footer {
    padding: 0.75rem;
  }
  
  .chat-assistant-header h3 {
    font-size: 0.9rem;
  }
  
  .current-page-info {
    font-size: 0.7rem;
  }
  
  .reset-btn, .minimize-btn {
    width: 28px;
    height: 28px;
  }
  
  .reset-btn svg, .minimize-btn svg {
    width: 16px;
    height: 16px;
  }
}

@media (max-height: 600px) {
  .chat-assistant {
    height: calc(100vh - 80px);
  }
}

/* Header actions container */
.header-actions {
  display: flex;
  align-items: center;
}

/* Reset button */
.reset-btn {
  background: none;
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s;
  margin-right: 8px;
  opacity: 0.8;
}

.reset-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 1;
}

.reset-btn svg {
  width: 18px;
  height: 18px;
}

/* Current page indicator */
.current-page-info {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  padding: 4px 0 0;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Persistence indicator */
.persistence-indicator {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 4px;
}

.persistence-indicator svg {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

/* Message regarding navigation */
.system-message:contains("navigated to") {
  background-color: #ecfdf5;
  border-left-color: #10b981;
  color: #065f46;
}

/* Page change notification */
.page-change-notification {
  text-align: center;
  padding: 8px 0;
  font-size: 0.85rem;
  color: #64748b;
  background-color: #f8fafc;
  margin: 10px 0;
  border-radius: 20px;
}

/* Update connect button */
.connect-btn {
  width: 100%;
  background: linear-gradient(135deg, #4f46e5, #6366f1) !important;
  border: none !important;
  padding: 0.8rem 1.5rem !important;
  border-radius: 24px !important;
  font-weight: 500 !important;
  font-size: 0.9rem !important;
  transition: all 0.2s ease-in-out !important;
  box-shadow: 0 2px 5px rgba(99, 102, 241, 0.2) !important;
}

/* Responsive adjustments for the header */
@media (max-width: 576px) {
  .chat-assistant-header h3 {
    font-size: 0.9rem;
  }
  
  .current-page-info {
    font-size: 0.7rem;
  }
  
  .reset-btn, .minimize-btn {
    width: 28px;
    height: 28px;
  }
  
  .reset-btn svg, .minimize-btn svg {
    width: 16px;
    height: 16px;
  }
} 