/* Main container */
.qa-container {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(135deg, #f8fafc, #eef2ff);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Inter', 'Segoe UI', -apple-system, BlinkMacSystemFont, sans-serif;
}

/* Title section */
.qa-title {
  text-align: center;
  padding: 1.5rem 1rem 1rem;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qa-title h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #1e293b;
  margin: 0;
  background: linear-gradient(135deg, #4f46e5, #6366f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  letter-spacing: -0.5px;
  position: relative;
}

.qa-title h1::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, #4f46e5, #6366f1);
  border-radius: 3px;
}

/* Info section */
.qa-info-section {
  background: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  margin: 0.5rem 1rem;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 5;
}

.info-container {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  box-shadow: 0 2px 6px rgba(79, 70, 229, 0.08);
  border: 1px solid rgba(79, 70, 229, 0.1);
  position: relative;
  overflow: hidden;
}

.info-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #4f46e5, #6366f1);
}

.info-icon {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  background: linear-gradient(135deg, #4f46e5, #6366f1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 10px rgba(79, 70, 229, 0.3);
}

.info-icon svg {
  width: 28px;
  height: 28px;
}

.info-content {
  flex: 1;
}

.info-content h2 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #1e293b;
  margin-top: 0;
  margin-bottom: 0.75rem;
}

.info-content p {
  color: #475569;
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.info-points {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.info-points li {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  background: #f8fafc;
  padding: 0.75rem;
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.info-points li:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.info-point-icon {
  font-size: 1.25rem;
  flex-shrink: 0;
}

.info-point-text {
  flex: 1;
  font-size: 0.9rem;
  color: #475569;
}

.info-point-text strong {
  color: #1e293b;
}

.info-footnote {
  font-size: 0.85rem;
  color: #64748b;
  font-style: italic;
  border-top: 1px dashed rgba(100, 116, 139, 0.2);
  padding-top: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

/* Main chat area */
.qa-content {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  overflow: hidden;
  position: relative;
}

/* Chat card */
.qa-card {
  background: white;
  border-radius: 16px;
  box-shadow: 
    0 10px 25px rgba(0, 0, 0, 0.07),
    0 6px 12px rgba(0, 0, 0, 0.04);
  width: 100%;
  max-width: 800px;
  height: calc(72vh - 20px);
  margin: 0.5rem auto 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: none !important;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Room connection header */
.qa-card .card-header {
  background: white !important;
  border-bottom: 1px solid #f1f5f9 !important;
  padding: 1.25rem !important;
  display: flex;
  position: relative;
}

.qa-card .card-header::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, 
    transparent, 
    rgba(99, 102, 241, 0.3), 
    transparent
  );
}

.qa-card .card-header form {
  width: 100%;
}

/* Message container */
.conversation-container {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  background-color: #ffffff;
  background-image: 
    radial-gradient(#eef2ff 0.5px, transparent 0.5px),
    radial-gradient(#eef2ff 0.5px, #ffffff 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  -ms-overflow-style: none;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 transparent;
  position: relative;
}

.conversation-container::-webkit-scrollbar {
  width: 6px;
}

.conversation-container::-webkit-scrollbar-track {
  background: transparent;
}

.conversation-container::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 20px;
}

/* Message styling */
.message {
  margin-bottom: 1.5rem;
  animation: fadeIn 0.3s ease;
  max-width: 100%;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(8px); }
  to { opacity: 1; transform: translateY(0); }
}

/* System message */
.system .system-message {
  background-color: #f8fafc;
  border-left: 3px solid #94a3b8;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  color: #64748b;
  font-size: 0.9rem;
  font-style: italic;
  margin: 0 auto;
  max-width: 85%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

/* User question */
.user-question {
  background: linear-gradient(135deg, #4f46e5, #6366f1);
  color: white;
  padding: 0.875rem 1.25rem;
  border-radius: 18px 18px 4px 18px;
  margin-left: auto;
  max-width: 75%;
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
  position: relative;
  line-height: 1.5;
  font-size: 0.95rem;
  transform-origin: bottom right;
  animation: popInRight 0.3s ease;
}

@keyframes popInRight {
  from { opacity: 0; transform: translateX(10px) scale(0.97); }
  to { opacity: 1; transform: translateX(0) scale(1); }
}

.user-question strong {
  opacity: 0.9;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  display: block;
  margin-bottom: 4px;
}

/* AI answer */
.ai-answer {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 18px 18px 18px 4px;
  padding: 1rem 1.25rem;
  margin-right: auto;
  max-width: 75%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  position: relative;
  transform-origin: bottom left;
  animation: popInLeft 0.3s ease;
}

@keyframes popInLeft {
  from { opacity: 0; transform: translateX(-10px) scale(0.97); }
  to { opacity: 1; transform: translateX(0) scale(1); }
}

.ai-answer .question {
  font-size: 0.8rem;
  color: #64748b;
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #f1f5f9;
  font-weight: 500;
}

.ai-answer .answer {
  color: #1e293b;
  line-height: 1.6;
  font-size: 0.95rem;
  white-space: pre-line;
}

.ai-answer strong {
  color: #4f46e5;
  font-weight: 600;
}

/* Status message */
.status-message {
  color: #64748b;
  font-style: italic;
  text-align: center;
  padding: 0.75rem 1rem;
  margin: 1rem auto;
  background-color: rgba(248, 250, 252, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 24px;
  display: inline-block;
  max-width: 85%;
  animation: pulse 1.5s infinite;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

@keyframes pulse {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

.status-message .spinner-border {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.5rem;
  vertical-align: middle;
  border-width: 0.1rem;
  border-color: #6366f1 transparent #6366f1 transparent;
}

/* Error message */
.error-message {
  color: #dc2626;
  background-color: #fef2f2;
  border-left: 3px solid #ef4444;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  margin: 1rem auto;
  max-width: 85%;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(239, 68, 68, 0.1);
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  20%, 60% { transform: translateX(-5px); }
  40%, 80% { transform: translateX(5px); }
}

/* Footer */
.qa-card .card-footer {
  background-color: white !important;
  border-top: 1px solid #f1f5f9 !important;
  padding: 1.25rem !important;
  position: relative;
}

.qa-card .card-footer::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, 
    transparent, 
    rgba(99, 102, 241, 0.3), 
    transparent
  );
}

/* Form controls */
.form-control {
  border-radius: 24px !important;
  padding: 0.75rem 1.25rem !important;
  border: 1px solid #e2e8f0 !important;
  background-color: #f8fafc !important;
  font-size: 0.95rem !important;
  transition: all 0.2s ease-in-out !important;
  box-shadow: none !important;
}

.form-control:focus {
  border-color: #6366f1 !important;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.15) !important;
  background-color: white !important;
}

.form-control:disabled {
  background-color: #f1f5f9 !important;
  opacity: 0.7;
}

/* Buttons */
.btn {
  border-radius: 24px !important;
  padding: 0.75rem 1.5rem !important;
  font-weight: 500 !important;
  font-size: 0.95rem !important;
  transition: all 0.2s ease-in-out !important;
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
}

.btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.btn:active::after {
  opacity: 0.2;
}

.btn-primary {
  background: linear-gradient(135deg, #4f46e5, #6366f1) !important;
  border: none !important;
}

.btn-primary:hover:not(:disabled) {
  background: linear-gradient(135deg, #4338ca, #4f46e5) !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.3) !important;
}

.btn-danger {
  background: linear-gradient(135deg, #ef4444, #dc2626) !important;
  border: none !important;
}

.btn-danger:hover:not(:disabled) {
  background: linear-gradient(135deg, #dc2626, #b91c1c) !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(239, 68, 68, 0.3) !important;
}

.btn:disabled {
  opacity: 0.65 !important;
}

/* Empty state */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #94a3b8;
  padding: 2rem;
  text-align: center;
}

.empty-state p {
  margin: 1rem 0 0;
  font-size: 0.95rem;
  max-width: 240px;
  line-height: 1.5;
}

.empty-state svg {
  width: 3.5rem;
  height: 3.5rem;
  color: #cbd5e1;
  opacity: 0.7;
  filter: drop-shadow(0 2px 4px rgba(15, 23, 42, 0.05));
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

/* Responsive styles */
@media (min-width: 1024px) {
  .qa-title h1 {
    font-size: 2.25rem;
  }
  
  .qa-card {
    max-width: 900px;
    margin: 1.5rem auto;
  }
  
  .user-question, .ai-answer {
    max-width: 65%;
  }
  
  .qa-card:hover {
    transform: translateY(-5px);
    box-shadow: 
      0 15px 30px rgba(0, 0, 0, 0.08),
      0 10px 15px rgba(0, 0, 0, 0.03);
  }
}

@media (max-width: 768px) {
  .qa-title {
    padding: 1rem 0.5rem;
  }
  
  .qa-title h1 {
    font-size: 1.5rem;
  }
  
  .qa-content {
    padding: 0 0.5rem;
  }
  
  .qa-card {
    height: calc(65vh - 20px);
    margin-top: 0.5rem;
    border-radius: 12px;
  }
  
  .conversation-container {
    padding: 1rem;
  }
  
  .form-control, .btn {
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
  }
  
  .user-question, .ai-answer {
    max-width: 85%;
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
  }
  
  .info-container {
    flex-direction: column;
    padding: 1.25rem;
    gap: 1rem;
  }
  
  .info-icon {
    margin: 0 auto;
  }
  
  .info-content h2 {
    text-align: center;
    font-size: 1.2rem;
  }
  
  .info-points li {
    padding: 0.5rem;
  }
  
  .qa-info-section {
    padding: 0.75rem;
    margin: 0.5rem 0.5rem;
  }
  
  .info-points {
    gap: 0.5rem;
  }
}

/* Initial load animation */
.initial-load {
  animation: fadeInPage 0.6s ease-out;
}

@keyframes fadeInPage {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Connection indicator */
.connection-indicator {
  display: inline-flex;
  align-items: center;
  margin-top: 8px;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: rgba(240, 253, 244, 0.8);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.85rem;
  color: #065f46;
  transition: all 0.3s ease;
}

.connection-indicator small {
  font-weight: 500;
}

.connection-indicator .status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 0.5rem;
  position: relative;
}

.connection-indicator .status-dot.connected {
  background-color: #10b981;
  box-shadow: 0 0 0 rgba(16, 185, 129, 0.4);
  animation: pulse-green 1.5s infinite;
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0.7);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(16, 185, 129, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0);
  }
}

/* Button loading state enhancement */
.btn-loading {
  position: relative;
  pointer-events: none;
}

.btn-loading .spinner-border {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

/* Input focus enhancement */
.form-control:focus {
  border-color: #6366f1 !important;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.15) !important;
  background-color: white !important;
  transform: translateY(-1px);
  transition: transform 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease;
} 