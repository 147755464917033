* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
  }
  
  .study-app {
    padding: 2rem;
    background: linear-gradient(to right, #f8f9fa, #e9ecef);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .study-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #343a40;
  }
  
  .controls {
    margin-bottom: 1.5rem;
    width: 60%;
  }
  
  label {
    font-size: 1.2rem;
    font-weight: bold;
    color: #495057;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .select-input,
  .number-input {
    width: 100%;
    padding: 0.7rem;
    font-size: 1rem;
    border: 2px solid #ced4da;
    border-radius: 8px;
    outline: none;
    transition: all 0.3s ease;
  }
  
  .select-input:focus, .number-input:focus {
    border-color: #495057;
  }
  
  .topic-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
  }
  
  .topic-item {
    background: #ffffff;
    padding: 0.7rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 0.1rem;
    font-size: 1rem;
  }
  
  .flashcard-container {
    margin-top: 2rem;
    padding: 2rem;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .nav-button {
    background: #007bff;
    color: white;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-left: 10px;
 
  }
  
  .nav-button:disabled {
    background: #ced4da;
    cursor: not-allowed;
  }
  
  .nav-button:not(:disabled):hover {
    background: #0056b3;
  }
  
  .placeholder {
    font-size: 1.5rem;
    color: #6c757d;
    margin-top: 2rem;
  }
  @media screen and (max-width: 768px) {
    .nav-button {
      background: #007bff;
      color: white;
      padding: 0.8rem 1.5rem;
      font-size: 1rem;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background 0.3s ease;
    }
  }