@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  /* STEM Color Palette - Modern, vibrant colors */
  --primary: #3b82f6;  /* Bright blue */
  --primary-light: #60a5fa;
  --primary-dark: #2563eb;
  --secondary: #8b5cf6; /* Purple */
  --secondary-light: #a78bfa;
  --secondary-dark: #7c3aed;
  --accent: #10b981;   /* Green */
  --accent-light: #34d399;
  --accent-dark: #059669;
  --white: #ffffff;
  --black: #000000;
  --text-dark: #1e293b;
  --text-muted: #64748b;
  --background-light: #f8fafc;
  --background-card: #ffffff;
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e0;
  --gray-400: #94a3b8;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0f172a;

  /* Shadows */
  --shadow-xs: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);

  /* Transitions */
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;
  
  /* Border Radius */
  --radius-sm: 0.25rem;
  --radius-md: 0.375rem;
  --radius-lg: 0.5rem;
  --radius-xl: 0.75rem;
  --radius-2xl: 1rem;
  --radius-3xl: 1.5rem;
  --radius-full: 9999px;
}

/* ==============================
   Global Styles
   ============================== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  background-color: var(--background-light);
  color: var(--text-dark);
}

/* ==============================
   Navbar Styles - New Modern Design
   ============================== */
.stem-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 1rem 2rem;
  transition: all var(--transition-normal);
}

.stem-navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: var(--shadow-md);
  padding: 0.75rem 2rem;
}

.navbar-container {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo-img {
  height: 40px;
  transition: transform var(--transition-normal);
}

.navbar-logo-img:hover {
  transform: scale(1.05);
}

.navbar-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: var(--gray-700);
  font-weight: 500;
  text-decoration: none;
  font-size: 0.95rem;
  position: relative;
  padding: 0.5rem 0;
  transition: color var(--transition-normal);
}

.stem-navbar.scrolled .nav-link {
  color: var(--gray-800);
}

.nav-link:hover {
  color: var(--primary);
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, var(--primary), var(--secondary));
  transition: width var(--transition-normal);
  border-radius: var(--radius-full);
}

.nav-link:hover::after {
  width: 100%;
}

.navbar-actions {
  display: flex;
  gap: 1rem;
}

.nav-button {
  padding: 0.6rem 1.25rem;
  border-radius: var(--radius-lg);
  font-weight: 500;
  text-decoration: none;
  transition: all var(--transition-normal);
  font-size: 0.95rem;
}

.secondary-nav-button {
  color: var(--primary);
  background-color: rgba(59, 130, 246, 0.1);
  border: 1px solid transparent;
}

.secondary-nav-button:hover {
  background-color: rgba(59, 130, 246, 0.15);
  transform: translateY(-2px);
}

.primary-nav-button {
  color: white;
  background: linear-gradient(135deg, var(--primary), var(--primary-dark));
  border: 1px solid transparent;
  box-shadow: 0 2px 5px rgba(37, 99, 235, 0.3);
}

.primary-nav-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(37, 99, 235, 0.4);
}

/* ==============================
   STEM Header Styles
   ============================== */
.stem-header {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8rem 2rem 3rem;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.stem-header.visible {
  opacity: 1;
  transform: translateY(0);
}

.stem-header-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #f0f7ff 0%, #f5f3ff 100%);
  z-index: -2;
}

.stem-header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 20% 30%, rgba(59, 130, 246, 0.08) 0%, transparent 35%),
    radial-gradient(circle at 80% 70%, rgba(139, 92, 246, 0.1) 0%, transparent 40%);
  z-index: -1;
}

.stem-header-content {
  width: 100%;
  max-width: 1400px;
  display: grid;
  grid-template-columns: 0.7fr 2fr 1.5fr;
  gap: 3rem;
  align-items: center;
}

/* Logo */
.stem-header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stem-logo {
  width: 130px;
  height: auto;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  transition: transform var(--transition-normal);
}

.stem-logo:hover {
  transform: scale(1.1);
  filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
}

/* Text Content */
.stem-header-text {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}

.stem-title {
  font-family: 'Poppins', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--gray-800);
  line-height: 1.1;
}

.stem-title span {
  color: var(--primary);
  position: relative;
  display: block;
  margin-top: 0.25rem;
  font-size: 3.75rem;
}

.stem-title span::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 180px;
  height: 6px;
  background: linear-gradient(90deg, var(--primary), var(--secondary));
  border-radius: 3px;
  margin-left:37%;
}

.stem-subtitle {
  font-size: 1.25rem;
  color: var(--text-muted);
  max-width: 600px;
  line-height: 1.6;
}

/* Feature Items */
.stem-header-features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin: 0.5rem 0 1.5rem;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  padding: 1.5rem;
  border-radius: var(--radius-xl);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, var(--primary-light), var(--primary));
  color: white;
  border-radius: var(--radius-lg);
  box-shadow: 0 4px 8px rgba(59, 130, 246, 0.3);
  transition: transform var(--transition-normal);
}

.feature-item:hover .feature-icon {
  transform: scale(1.1) rotate(5deg);
}

.feature-text h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--gray-800);
}

.feature-text p {
  font-size: 0.875rem;
  color: var(--text-muted);
  line-height: 1.5;
}

/* Action Buttons */
.stem-header-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.primary-button,
.secondary-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.85rem 1.75rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: var(--radius-lg);
  text-decoration: none;
  transition: all var(--transition-normal);
}

.primary-button {
  background: linear-gradient(135deg, var(--primary), var(--primary-dark));
  color: white;
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.3);
}

.primary-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(37, 99, 235, 0.4);
  color: white;
}

.secondary-button {
  background: rgba(255, 255, 255, 0.7);
  color: var(--primary-dark);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--gray-200);
}

.secondary-button:hover {
  background: rgba(255, 255, 255, 0.9);
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  color: var(--primary-dark);
}

/* Visual Content */
.stem-header-visual {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.visual-container {
  position: relative;
  width: 320px;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visual-element {
  width: 280px;
  height: 280px;
  overflow: hidden;
  border-radius: var(--radius-2xl);
  background: rgba(255, 255, 255, 0.8);
  box-shadow: var(--shadow-xl);
  border: 5px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  transition: transform var(--transition-normal);
}

.visual-element:hover {
  transform: scale(1.03);
}

.header-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pulse-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    rgba(59, 130, 246, 0.25) 0%,
    rgba(139, 92, 246, 0.15) 50%,
    transparent 70%
  );
  animation: pulse 3s infinite ease-in-out;
  z-index: 1;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0.5;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0.5;
  }
}

/* Scroll Indicator */
.scroll-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  opacity: 0.7;
  transition: opacity var(--transition-normal);
}

.scroll-indicator:hover {
  opacity: 1;
}

.scroll-indicator span {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-muted);
  margin-bottom: 0.75rem;
  letter-spacing: 0.05em;
}

.scroll-arrow {
  width: 20px;
  height: 20px;
  border-right: 3px solid var(--primary);
  border-bottom: 3px solid var(--primary);
  transform: rotate(45deg);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: rotate(45deg) translateY(0);
  }
  40% {
    transform: rotate(45deg) translateY(10px);
  }
  60% {
    transform: rotate(45deg) translateY(5px);
  }
}

/* ==============================
   Responsive Styles
   ============================== */
@media (max-width: 1200px) {
  .stem-header-content {
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
  }
  
  .stem-header-logo {
    display: none;
  }
  
  .stem-title {
    font-size: 3rem;
  }
  
  .stem-title span {
    font-size: 3.25rem;
  }
  
  .stem-header-features {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

@media (max-width: 992px) {
  body.menu-open {
    overflow: hidden; /* Prevent background scrolling when menu is open */
  }

  .stem-header-content {
    grid-template-columns: 1fr;
    padding: 1rem;
    gap: 3rem;
  }
  
  .stem-header-text {
    text-align: center;
    align-items: center;
  }
  
  .stem-subtitle {
    max-width: 100%;
  }
  
  .stem-header-visual {
    margin-top: 1rem;
  }
  
  .stem-header-features {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .stem-title span::after {
    left: 50%;
    width: 140px;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .stem-navbar {
    padding: 1rem;
  }
  
  .stem-navbar.scrolled {
    padding: 0.75rem 1rem;
  }
  
  .navbar-links {
    display: none;
  }
  
  .stem-header {
    min-height: auto;
    padding: 6rem 1rem 4rem;
  }
  
  .stem-title {
    font-size: 2.5rem;
  }
  
  .stem-title span {
    font-size: 2.75rem;
  }
  
  .stem-subtitle {
    font-size: 1.1rem;
  }
  
  .stem-header-features {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .feature-item {
    padding: 1.25rem;
  }
  
  .visual-container {
    width: 280px;
    height: 280px;
  }
  
  .visual-element {
    width: 240px;
    height: 240px;
  }
  
  .pulse-circle {
    width: 260px;
    height: 260px;
  }
  
  .stem-title span::after {
    width: 120px;
    margin-left: 0;
  }

  #LoginButton {
    padding: 8px 16px;
    font-size: 14px;
    min-width: 80px;
  }
  
  /* Enhanced styles for nav buttons on mobile */
  .nav-button {
    padding: 8px 16px;
    font-size: 15px;
    min-width: 90px;
    display: inline-block;
    text-align: center;
  }
  
  .primary-nav-button,
  .secondary-nav-button {
    margin: 0 5px;
  }
}

@media (max-width: 480px) {
  .stem-header-content {
    gap: 2rem;
  }
  
  .stem-title {
    font-size: 2.25rem;
  }
  
  .stem-title span {
    font-size: 2.5rem;
  }
  
  .stem-subtitle {
    font-size: 1rem;
  }
  
  .stem-header-actions {
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
  }
  
  .primary-button,
  .secondary-button {
    width: 100%;
    text-align: center;
  }
  
  .visual-container {
    width: 240px;
    height: 240px;
  }
  
  .visual-element {
    width: 210px;
    height: 210px;
  }
  
  .pulse-circle {
    width: 230px;
    height: 230px;
  }
  
  /* Make navbar actions visible on smallest screens */
  .navbar-actions {
    display: flex;
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    justify-content: center;
    z-index: 100;
    gap: 0.75rem;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  #LoginButton {
    min-width: 100px;
    padding: 10px 16px;
    font-size: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .primary-nav-button {
    min-width: 100px;
    padding: 10px 16px;
    font-size: 15px;
    box-shadow: 0 2px 6px rgba(37, 99, 235, 0.4);
  }
  
  /* Adjust user menu for mobile */
  .user-menu {
    margin-right: 4px;
    width: auto;
  }
  
  .user-menu-button {
    min-width: 110px;
    padding: 10px 16px;
    justify-content: center;
    font-size: 15px;
  }
  
  /* Premium button styling for mobile */
  .premium-nav-button {
    min-width: 110px;
    padding: 10px 16px;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 6px rgba(139, 92, 246, 0.4);
  }
  
  /* Adjust cart badge for mobile */
  .cart-badge {
    position: absolute;
    top: -5px;
    right: -5px;
  }
  
  /* Semi-transparent background behind the fixed buttons */
  .navbar-actions::before {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90px;
    background: linear-gradient(to top, rgba(255,255,255,0.9) 60%, rgba(255,255,255,0));
    z-index: -1;
    pointer-events: none;
  }
}

/* Additional mobile optimizations */
@media (max-width: 576px) {
  .user-menu {
    width: auto;
  }

  .user-dropdown {
    width: 100%;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px 15px 0 0;
    padding: 1rem;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  }
}

/* User Menu Dropdown Styles */
.user-menu {
  position: relative;
}

.user-menu-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(59, 130, 246, 0.1);
  color: var(--primary);
  border: none;
  border-radius: var(--radius-lg);
  padding: 0.6rem 1.25rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all var(--transition-normal);
}

.user-menu-button:hover {
  background-color: rgba(59, 130, 246, 0.15);
  transform: translateY(-2px);
}

.dropdown-arrow {
  font-size: 0.7rem;
  margin-left: 0.5rem;
  transition: transform 0.2s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.user-dropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  min-width: 180px;
  background: white;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-lg);
  overflow: hidden;
  z-index: 1010;
  display: block !important;
}

.dropdown-item {
  display: block;
  padding: 0.75rem 1.25rem;
  color: var(--gray-700);
  text-decoration: none;
  font-weight: 500;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  text-align: left;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: var(--gray-100);
  color: var(--primary);
}

/* Media query adjustments for mobile users */
@media (max-width: 576px) {
  .user-menu {
    width: 100%;
  }

  .user-dropdown {
    width: 100%;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px 15px 0 0;
    padding: 1rem;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  }
}

/* Premium Button Style */
.premium-nav-button {
  position: relative;
  color: white;
  background: linear-gradient(135deg, var(--secondary), var(--secondary-dark));
  border: 1px solid transparent;
  box-shadow: 0 2px 5px rgba(139, 92, 246, 0.3);
}

.premium-nav-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(139, 92, 246, 0.4);
}

.cart-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: var(--error);
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: 700;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Mobile Menu Toggle */
.mobile-menu-toggle {
  display: none; /* Hide by default */
  position: relative;
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  width: 44px;
  height: 44px;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  outline: none;
}

.menu-icon {
  display: block;
  position: relative;
  width: 24px;
  height: 2px;
  background-color: var(--gray-700);
  transition: all 0.3s ease;
}

.stem-navbar.scrolled .menu-icon {
  background-color: var(--gray-800);
}

.menu-icon::before,
.menu-icon::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: var(--gray-700);
  transition: all 0.3s ease;
}

.stem-navbar.scrolled .menu-icon::before,
.stem-navbar.scrolled .menu-icon::after {
  background-color: var(--gray-800);
}

.menu-icon::before {
  top: -8px;
}

.menu-icon::after {
  top: 8px;
}

.mobile-menu-toggle.active .menu-icon {
  background-color: transparent;
}

.mobile-menu-toggle.active .menu-icon::before {
  transform: translateY(8px) rotate(45deg);
}

.mobile-menu-toggle.active .menu-icon::after {
  transform: translateY(-8px) rotate(-45deg);
}

/* Mobile Menu Styles */
@media (max-width: 992px) {
  .mobile-menu-toggle {
    display: block; /* Show on mobile */
    will-change: transform;
    transform: translateZ(0);
  }

  .navbar-links {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    max-width: 300px;
    height: 100vh;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    flex-direction: column;
    padding: 80px 2rem 2rem;
    transition: right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    z-index: 999;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    will-change: transform;
    transform: translateZ(0);
  }

  .navbar-links.mobile-active {
    right: 0;
    display: flex !important;
    visibility: visible !important;
  }

  .navbar-links .nav-link {
    width: 100%;
    padding: 1rem 0;
    text-align: left;
    transform: translateZ(0);
    will-change: opacity, transform;
    transition: all 0.2s ease;
  }

  /* Fix body scrolling when menu is open */
  body.menu-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  
  /* Control visibility of auth buttons */
  .hidden-when-menu-open {
    display: none !important;
  }
  
  /* Style for auth buttons inside mobile menu */
  .navbar-links.mobile-active .mobile-auth-buttons .nav-button {
    width: 100%;
    text-align: center;
    padding: 12px 16px;
    margin: 0;
    font-size: 16px;
  }
  
  .navbar-links.mobile-active .mobile-auth-buttons .primary-nav-button {
    order: -1; /* Show Register button first in mobile menu */
  }
}

/* Additional mobile optimizations */
@media (max-width: 576px) {
  .user-menu {
    width: auto;
  }

  .user-dropdown {
    width: 100%;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px 15px 0 0;
    padding: 1rem;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  }
}

/* Styles for mobile auth buttons (in slide-out menu) */
.mobile-auth-buttons {
  display: none; /* Hide by default */
}

/* When mobile menu is open, show these buttons */
.navbar-links.mobile-active .mobile-auth-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.5rem;
  gap: 0.75rem;
}
