/* General Container */
.code-editor-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(to bottom, #1a1a2e, #16213e);
  min-height: 100vh;
  color: #e3e3e3;
}

/* Side-by-side layout for larger screens */
.editor-output-container {
  display: flex;
  width: 95%;
  max-width: 1800px;
  gap: 20px;
  margin-top: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
  background: rgba(30, 30, 46, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(59, 130, 246, 0.3);
}

/* Editor Styling */
.editor-container {
  flex: 3;
  background: #1e1e2e;
  padding: 20px;
  border-radius: 8px 0 0 8px;
  min-width: 300px;
  position: relative;
  border-right: 1px solid rgba(59, 130, 246, 0.2);
}

/* Output Container */
.output-container {
  flex: 1;
  background: #282a36;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  font-family: 'JetBrains Mono', 'Fira Code', monospace;
  position: relative;
  border-left: 2px solid rgba(59, 130, 246, 0.2);
}

.output-title {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #3b82f6;
  border-bottom: 1px solid rgba(59, 130, 246, 0.3);
  padding-bottom: 10px;
  font-weight: 600;
}

.output-box {
  background: rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
  min-height: 150px;
  max-height: 300px;
  overflow-y: auto;
  white-space: pre-wrap;
  font-family: 'JetBrains Mono', 'Fira Code', monospace;
  font-size: 0.9rem;
  line-height: 1.5;
  border: 1px solid rgba(59, 130, 246, 0.3);
}

.output-box.error {
  border-color: #ff5555;
}

.output-box p {
  margin: 0;
  padding: 2px 0;
}

/* Run Button */
.run-button {
  margin-top: 10px;
  padding: 12px 20px;
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 4px 8px rgba(59, 130, 246, 0.3);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.run-button:hover {
  background: linear-gradient(135deg, #2563eb, #1d4ed8);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(59, 130, 246, 0.4);
}

.run-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(59, 130, 246, 0.3);
}

.run-button.loading {
  opacity: 0.7;
  cursor: wait;
}

/* Analyze Button Container */
.analyze-button-container {
  margin-top: 20px;
  padding: 15px;
  background: rgba(25, 25, 35, 0.6);
  border-radius: 8px;
  border: 1px solid rgba(59, 130, 246, 0.3);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.analyze-button-container:hover {
  border-color: rgba(59, 130, 246, 0.7);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

/* Analyze Button */
.analyze-button {
  margin-top: 0;
  padding: 12px 20px;
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 8px rgba(59, 130, 246, 0.3);
}

.analyze-button:hover:not(:disabled) {
  background: linear-gradient(135deg, #2563eb, #1d4ed8);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(59, 130, 246, 0.4);
}

.analyze-button:active:not(:disabled) {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(59, 130, 246, 0.3);
}

.analyze-button:disabled {
  background: linear-gradient(135deg, #888, #aaa);
  color: #ddd;
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.7;
}

/* WebSocket Notice */
.websocket-notice {
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(59, 130, 246, 0.1);
  color: #3b82f6;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  border-left: 4px solid #3b82f6;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Editor Actions Container */
.editor-actions {
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

/* Output Section */
.output-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Instructional Container */
.instructional-container {
  background: linear-gradient(to right, rgba(59, 130, 246, 0.1), rgba(59, 130, 246, 0.05));
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 20px;
  max-width: 95%;
  margin: 0 auto 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(59, 130, 246, 0.2);
}

.instructional-title {
  color: #3b82f6;
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
}

.instructional-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 60px;
  background: linear-gradient(to right, #3b82f6, #60a5fa);
  border-radius: 3px;
}

.instructional-text {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #1e293b;
}

.instructional-list {
  margin: 15px 0 15px 20px;
  color: #334155;
}

.instructional-list li {
  margin-bottom: 8px;
  position: relative;
  padding-left: 5px;
}

.instructional-note {
  font-size: 0.9rem;
  color: #64748b;
  font-style: italic;
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px dashed rgba(59, 130, 246, 0.3);
}

/* Feedback container */
.feedback-container {
  background: rgba(30, 30, 46, 0.8);
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  border: 1px solid rgba(59, 130, 246, 0.3);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.feedback-container h3 {
  color: #3b82f6;
  font-size: 1.2rem;
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(59, 130, 246, 0.2);
}

.feedback-content {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 12px;
  max-height: 300px;
  overflow-y: auto;
}

.feedback-content pre {
  margin: 0;
  white-space: pre-wrap;
  font-family: 'JetBrains Mono', 'Fira Code', monospace;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #f1f5f9;
}

/* Responsive styles */
@media (max-width: 992px) {
  .editor-output-container {
    flex-direction: column;
  }
  
  .editor-container,
  .output-section {
    width: 100%;
    border-radius: 8px;
  }
  
  .editor-container {
    border-right: none;
    border-bottom: 1px solid rgba(59, 130, 246, 0.2);
  }
}

@media (max-width: 768px) {
  .instructional-container {
    padding: 20px;
  }
  
  .instructional-title {
    font-size: 1.5rem;
  }
}
